/**
 * Created by neo on 22.11.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RouteChallenge } from '../../../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { Col, Row } from 'reactstrap';
import { Button, DatePicker, Form, Input, InputNumber, message, Popconfirm, Space, Upload } from 'antd';
import { TranslationInputArray } from '../../../../../Components/Translation/TranslationInputArray';
import dayjs, { Dayjs } from 'dayjs';
import { RouteChallengeMapEditor } from './RouteChallengeMapEditor';
import { reaction, runInAction } from 'mobx';
import { RcFile } from 'antd/es/upload';
import MediaService from '../../../../../Services/MediaService';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { MapRegion } from '../../../../../Model/Engagement/RouteChallenge/MapRegion';
import AsyncSelect from 'react-select/async';
import { Gym } from '../../../../../Model/Gym/Gym';
import { Media } from '../../../../../Model/Media/Media';
import { ExploreEntryModalMediaField } from '../../../../Coach/Explore/ExploreEntryModalMediaField';
import { MediaEditModal } from '../../../../MetaData/Media/MediaEditModal/MediaEditModal';

export type RouteChallengeEditorTabProps = {
  challenge: RouteChallenge;
};

export const RouteChallengeEditorTab: React.FC<RouteChallengeEditorTabProps> = observer(({ challenge }) => {
  const [uploading, setUploading] = useState(false);
  const [currentMapRegion, setCurrentMapRegion] = useState(challenge.mapCenter);
  const [gym, setGym] = useState<Gym | undefined>();
  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();

  useEffect(
    () =>
      reaction(
        () => challenge.gymId,
        (gymId) => {
          setGym(undefined);
          if (gymId) {
            Gym.get(gymId).then((res) => setGym(res));
          }
        },
        { fireImmediately: true },
      ),
    [challenge],
  );

  const handleChangeStartDateTime = React.useCallback(
    (date) => {
      const newDate = (date ?? dayjs().add(1, 'month')).startOf('day').toDate();
      runInAction(() => (challenge.startDateTime = newDate));
    },
    [challenge],
  );

  const handleChangeEndDateTime = React.useCallback(
    (date) => {
      const newDate = (date ?? dayjs(challenge.startDateTime).add(1, 'month')).endOf('day').toDate();
      runInAction(() => (challenge.endDateTime = newDate));
    },
    [challenge],
  );

  const handleChangeDisplayStartDateTime = React.useCallback(
    (date) => {
      const newDate = (date ?? dayjs(challenge.startDateTime).subtract(1, 'month')).startOf('day').toDate();
      runInAction(() => (challenge.displayStartDateTime = newDate));
    },
    [challenge],
  );

  const handleChangeDisplayEndDateTime = React.useCallback(
    (date) => {
      const newDate = (date ?? dayjs(challenge.endDateTime).add(1, 'week')).endOf('day').toDate();
      runInAction(() => (challenge.displayEndDateTime = newDate));
    },
    [challenge],
  );

  const beforeUpload = (file: RcFile) => {
    if (!file.type.toLowerCase().startsWith('image/')) {
      message.error('You can only upload JPG/PNG file!');
    } else if (file.size / 1024 / 1024 > 2) {
      message.error('Image must smaller than 2MB!');
    } else {
      setUploading(true);
      MediaService.uploadMedia(file)
        .then((media) => {
          runInAction(() => challenge.images.push(media));
        })
        .catch(() => message.error('Failed to upload image'))
        .finally(() => setUploading(false));
    }

    return false;
  };

  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleBoundsChanged = React.useCallback(({ detail }) => {
    console.log('bounds', detail);
    // lon = x, lat = y
    const longitudeDelta = Math.max(
      Math.abs(detail.bounds.west - detail.center.lng),
      Math.abs(detail.bounds.east - detail.center.lng),
    );
    const latitudeDelta = Math.max(
      Math.abs(detail.bounds.north - detail.center.lat),
      Math.abs(detail.bounds.south - detail.center.lat),
    ); // Math.abs(detail.bounds.north - detail.bounds.south);
    setCurrentMapRegion(
      new MapRegion({
        longitude: detail.center.lng,
        latitude: detail.center.lat,
        longitudeDelta,
        latitudeDelta,
      }),
    );
  }, []);

  const fetchGym = React.useCallback((query: string) => Gym.find({ query }), []);

  const optionLabel = React.useCallback((obj) => obj.name, []);

  const optionValue = React.useCallback((obj) => obj, []);

  const handleChangeObject = React.useCallback(
    (value?: Gym) => {
      runInAction(() => (challenge.gymId = value?.id));
    },
    [challenge],
  );

  const handleClickMedia = React.useCallback((media: Media) => {
    setSelectedMedia(media);
  }, []);

  const handleCloseMedia = React.useCallback(() => setSelectedMedia(undefined), []);

  const handleImageUploaded = React.useCallback(
    (media: Media) => {
      runInAction(() => challenge.images.push(media));
    },
    [challenge],
  );

  const handleMediaSave = React.useCallback(
    (media: Media) => {
      runInAction(() => {
        if (challenge.images.some((v) => v.id === media.id)) {
          const index = challenge.images.findIndex((v) => v.id === media.id);
          if (index !== -1) {
            challenge.images[index] = media;
          }
        }
      });

      challenge.save().then(() => message.success('Challenge saved'));
    },
    [challenge],
  );

  const handleRemoveImage = React.useCallback(
    (media: Media) => {
      runInAction(() => (challenge.images = challenge.images.filter((v) => v.id !== media.id)));
    },
    [challenge],
  );

  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <TranslationInputArray entity={challenge} field={'name'} label={'Name'} />
        </Col>
        <Col md={6}>
          <TranslationInputArray entity={challenge} type="textarea" field={'description'} label={'Description'} />
        </Col>
        <Col xs={12}>
          <Form.Item label="Gym / Company">
            <AsyncSelect
              value={gym}
              isClearable={true}
              loadOptions={fetchGym as any}
              getOptionLabel={optionLabel}
              getOptionValue={optionValue as any}
              onChange={handleChangeObject as any}
            />
          </Form.Item>
        </Col>
        <Col md={6} xl={3}>
          <Form.Item label="Min Members per Team">
            <InputNumber
              value={challenge.minMembersPerTeam}
              onChange={(v) => (challenge.minMembersPerTeam = Number(v))}
              min={1}
              max={100}
            />
          </Form.Item>
        </Col>
        <Col md={6} xl={3}>
          <Form.Item label="Max Members per Team">
            <InputNumber
              value={challenge.maxMembersPerTeam}
              onChange={(v) => (challenge.maxMembersPerTeam = Number(v))}
              min={1}
              max={100}
            />
          </Form.Item>
        </Col>
        <Col xs={6} xl={3}>
          <Form.Item label="Start Date">
            <DatePicker value={dayjs(challenge.startDateTime)} onChange={handleChangeStartDateTime} />
          </Form.Item>
        </Col>
        <Col xs={6} xl={3}>
          <Form.Item label="End Date">
            <DatePicker value={dayjs(challenge.endDateTime)} onChange={handleChangeEndDateTime} />
          </Form.Item>
        </Col>
        <Col xs={6} xl={3}>
          <Form.Item label="Display Start Date" extra="When should the challenge be visible?">
            <DatePicker value={dayjs(challenge.displayStartDateTime)} onChange={handleChangeDisplayStartDateTime} />
          </Form.Item>
        </Col>
        <Col xs={6} xl={3}>
          <Form.Item label="Display End Date" extra="Until when should the challenge be visible?">
            <DatePicker value={dayjs(challenge.displayEndDateTime)} onChange={handleChangeDisplayEndDateTime} />
          </Form.Item>
        </Col>
        <Col xs={6}>
          <Form.Item label="Images">
            <div style={{ display: 'flex' }}>
              {challenge.images.concat([undefined] as any).map((media) => (
                <div key={media?.id} style={{ marginLeft: 8 }}>
                  <ExploreEntryModalMediaField
                    onRemove={handleRemoveImage}
                    onUploaded={handleImageUploaded}
                    onClick={handleClickMedia}
                    media={media}
                  />
                </div>
              ))}
            </div>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label="Map Center (lat, lon, latDelta, lonDelta)"
            extra="Move the map around and set the zoom level accordingly. Press then the button  to set a new map center"
          >
            <Space>
              <Input value={challenge.mapCenter.latitude} disabled={true} />
              <Input value={challenge.mapCenter.longitude} disabled={true} />
              <Input value={challenge.mapCenter.latitudeDelta} disabled={true} />
              <Input value={challenge.mapCenter.longitudeDelta} disabled={true} />
              <Button onClick={() => runInAction(() => (challenge.mapCenter = currentMapRegion))}>
                Set Map Center
              </Button>
            </Space>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <RouteChallengeMapEditor challenge={challenge} onBoundsChanged={handleBoundsChanged} />
        </Col>
      </Row>
      {selectedMedia && <MediaEditModal media={selectedMedia} onCancel={handleCloseMedia} onSave={handleMediaSave} />}
    </React.Fragment>
  );
});
