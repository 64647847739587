/**
 * Created by neo on 02.12.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { GymChallenge, GymChallengeStatus } from '../../../Model/GymChallenge/GymChallenge';
import { GymChallengeParticipant } from '../../../Model/GymChallenge/GymChallengeParticipant';
import { Badge, Button, Tag } from 'antd';
import { RouteChallenge } from '../../../Model/Engagement/RouteChallenge/RouteChallenge';
import { RouteChallengeTeam } from '../../../Model/Engagement/RouteChallenge/RouteChallengeTeam';

export type RouteChallengeTableRowProps = {
  challenge: RouteChallenge;
};

export const RouteChallengeTableRow: React.FC<RouteChallengeTableRowProps> = observer(({ challenge }) => {
  const [teamsCount, setTeamsCount] = useState(0);

  useEffect(() => {
    RouteChallengeTeam.count({ challengeId: challenge.id }).then((result) => setTeamsCount(result));
  }, [challenge]);

  const tagColor = (status: GymChallengeStatus) => {
    switch (status) {
      case 'in_progress':
        return 'cyan';
      case 'not_started':
        return 'yellow';
      case 'finished':
        return 'red';
      case 'achieved':
        return 'green';
    }
  };

  const handleCopy = React.useCallback(() => {
    const copy = challenge.copy();
    copy.displayStartDateTime = copy.startDateTime = dayjs().add(1, 'day').startOf('day').toDate();
    copy.displayEndDateTime = copy.endDateTime = dayjs().add(1, 'day').add(1, 'month').endOf('day').toDate();
    copy.save();
  }, [challenge]);

  return (
    <tr>
      <td>
        <Link to={`${challenge.id}`}>{challenge.id}</Link>
      </td>
      {/*<td>{challenge.gym?.name}</td>*/}
      <td>{challenge.defaultName}</td>
      <td>
        {challenge.started
          ? challenge.ended
            ? 'Ended'
            : 'Running'
          : dayjs(challenge.startDateTime).format('DD.MM.YYYY HH:mm')}
      </td>
      <td>{dayjs(challenge.endDateTime).format('DD.MM.YYYY HH:mm')}</td>
      {/*<td>*/}
      {/*  <Tag color={tagColor(challenge.status)}>{challenge.status}</Tag>*/}
      {/*</td>*/}
      <td>{challenge.totalCollectedPoints}</td>
      <td>{teamsCount}</td>
      <td>
        <Button type="link" onClick={handleCopy}>
          Copy
        </Button>
      </td>
    </tr>
  );
});
