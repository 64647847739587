/**
 * Created by neo on 04.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { CampaignCalendarEntry } from '../../../Model/CampaignCalendar/CampaignCalendarEntry';
import { Link } from 'react-router-dom';
import { Button, message, Popconfirm, Tag } from 'antd';
import dayjs from 'dayjs';

export type CampaignCalendarTableRowProps = {
  entry: CampaignCalendarEntry;
  onDelete?: () => void;
};

export const CampaignCalendarTableRow: React.FC<CampaignCalendarTableRowProps> = observer(({ entry, onDelete }) => {
  const handleDelete = () =>
    entry
      .delete()
      .then(() => onDelete?.())
      .then(() => message.success('Campaign deleted'))
      .catch(() => message.error('Failed to delete campaign'));

  return (
    <React.Fragment>
      <tr>
        <td>
          <Link to={`/customer-success/campaign-calendar/${entry.id}`}>{entry.id}</Link>
        </td>
        <td>{`${entry.defaultName}`}</td>
        <td>{entry.gym?.name ?? 'All'}</td>
        <td>{`${dayjs(entry.entryDate, 'YYYY-MM-DD').format('DD. MMMM YYYY')}\n${entry.entryTime}`}</td>
        <td>{entry.publishDate ? dayjs(entry.publishDate).format('DD.MM.YYYY') : '-'}</td>
        <td>{entry.unpublishDate ? dayjs(entry.unpublishDate).format('DD.MM.YYYY') : '-'}</td>
        <td>{entry.published ? <Tag color="success">Published</Tag> : <Tag color="error">Unpublished</Tag>}</td>
        <td>{entry.attachments.length}</td>

        <td>
          <Popconfirm title={'Are you sure?'} onConfirm={handleDelete}>
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </td>
      </tr>
    </React.Fragment>
  );
});
