/**
 * Created by neo on 04.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { PageResult } from '../../../Model/PageResult';
import { CampaignCalendarEntry } from '../../../Model/CampaignCalendar/CampaignCalendarEntry';
import { Col, Container, Row, Table } from 'reactstrap';
import { Pager } from '../../../Components/Pager';
import { CampaignCalendarTableRow } from './CampaignCalendarTableRow';
import { Button } from 'antd';
import { Route, Routes, useNavigate } from 'react-router';
import { CampaignCalendarEntryModal } from './CampaignCalendarModal/CampaignCalendarEntryModal';

export type CampaignCalendarIndexScreenProps = {};

export const CampaignCalendarIndexScreen: React.FC<CampaignCalendarIndexScreenProps> = observer((props) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [result, setResult] = useState(new PageResult<CampaignCalendarEntry>());

  useEffect(() => {
    PageResult.execute(CampaignCalendarEntry.find({ size: 50, page }), CampaignCalendarEntry.count(), page, 50).then((res) =>
      setResult(res),
    );
  }, [page]);

  const handleRefresh = () => {
    PageResult.execute(CampaignCalendarEntry.find({ size: 50, page }), CampaignCalendarEntry.count(), page, 50).then((res) =>
      setResult(res),
    );
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h1>Campaign Calendar</h1>
          </Col>
          <Col xs="auto">
            <Button type="primary" onClick={() => navigate(`/customer-success/campaign-calendar/create`)}>
              Create new Entry
            </Button>
          </Col>
        </Row>
        <Pager page={result} onPage={(newPage) => setPage(newPage)} />
        <Table striped hover size="sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Company</th>
              <th>Campaign Date</th>
              <th>Publish Date</th>
              <th>Unpublish Date</th>
              <th>Published</th>
              <th>Files</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {result.content.map((entry) => (
              <CampaignCalendarTableRow key={entry.id} entry={entry} onDelete={handleRefresh} />
            ))}
          </tbody>
        </Table>
      </Container>
      <Routes>
        <Route path=":campaignId" element={<CampaignCalendarEntryModal onSave={handleRefresh} />} />
      </Routes>
    </React.Fragment>
  );
});
