import * as React from 'react';
import { NavLink as RRLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, DropdownItem } from 'reactstrap';
import { DropdownHeader } from './DropdownHeader';
import { InfrastructureMenu } from './Header/InfrastructureMenu';
import { MetaDataMenu } from './Header/MetaDataMenu';
import { useState } from 'react';
import { useRootStore } from '../../Store/useRootStore';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Button } from 'antd';

export type HeaderProps = {
  onLogout?: () => void;
};

export const Header: React.FC<HeaderProps> = observer(({ onLogout }) => {
  const history = useNavigate();
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<string | undefined>(undefined);
  const { authentication } = useRootStore();

  const toggle = React.useCallback(() => {
    setOpen((v) => !v);
  }, []);

  const toggleDropdown = React.useCallback((newDrop?: string): void => {
    setDropdownOpen((d) => (d === newDrop ? '' : newDrop));
  }, []);

  const handleLogout = React.useCallback(() => {
    authentication.logout();
    onLogout && onLogout();
  }, [authentication, onLogout]);

  const handleClickLogo = React.useCallback(() => {
    history('/', { replace: true });
  }, [history]);

  return (
    <Navbar color="dark" dark expand="md" container>
      <NavbarBrand onClick={handleClickLogo}>kAdmin</NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={open} navbar>
        <Nav className="me-auto" navbar>
          <InfrastructureMenu onToggle={toggleDropdown} activeKey={dropdownOpen} />
          <MetaDataMenu onToggle={toggleDropdown} activeKey={dropdownOpen} />
          {authentication.hasAnyRole(['ROLE_SALES', 'ROLE_SUPPORT']) && (
            <NavItem className="nav-item">
              <NavLink tag={RRLink} to="/athlete" activeClassName="active">
                Athletes
              </NavLink>
            </NavItem>
          )}
          {authentication.isGodAdmin && (
            <DropdownHeader dropdown={'tracking'} activeKey={dropdownOpen} label={'Tracking'} onToggle={toggleDropdown}>
              <DropdownItem tag={RRLink} to="/tracking/activity-log" activeClassName="active">
                Activity Logs
              </DropdownItem>
            </DropdownHeader>
          )}
          {authentication.isGodAdmin && (
            <DropdownHeader dropdown="engagement" activeKey={dropdownOpen} label="Engagement" onToggle={toggleDropdown}>
              <DropdownItem tag={RRLink} to="/engagement/earn-model-templates" activeClassName="active">
                Earn Model Templates
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={RRLink} to="/coach/ledgers" activeClassName="active">
                Customer Ledgers
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={RRLink} to="/engagement/route-challenges" activeClassName="active">
                Route Challenges
              </DropdownItem>
              <DropdownItem tag={RRLink} to="/engagement/challenges" activeClassName="active">
                Company Challenges (Classic)
              </DropdownItem>
            </DropdownHeader>
          )}
          <DropdownHeader dropdown={'coach'} activeKey={dropdownOpen} label={'Coach'} onToggle={toggleDropdown}>
            <DropdownItem disabled>Fitness</DropdownItem>
            <DropdownItem tag={RRLink} to="/coach/program-template" activeClassName="active">
              Fitness Program Templates
            </DropdownItem>
            <DropdownItem tag={RRLink} to="/coach/workout-template" activeClassName="active">
              Workout Templates
            </DropdownItem>
            {authentication.isGodAdmin && (
              <DropdownItem tag={RRLink} to="/coach/workout-script" activeClassName="active">
                Workout Pipelines
              </DropdownItem>
            )}
            {authentication.isGodAdmin && (
              <DropdownItem tag={RRLink} to="/coach/workout-modules" activeClassName="active">
                Workout Modules
              </DropdownItem>
            )}
            {authentication.hasAnyRole(['SALES', 'SUPPORT']) && (
              <DropdownItem tag={RRLink} to="/coach/fitness-program" activeClassName="active">
                Fitness Programs
              </DropdownItem>
            )}
            <DropdownItem divider />
            <DropdownItem disabled>Mindfulness</DropdownItem>
            <DropdownItem tag={RRLink} to="/coach/mindfulness-template" activeClassName="active">
              Program Templates
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem disabled>Nutrition</DropdownItem>
            <DropdownItem tag={RRLink} to="/coach/diet/recipes" activeClassName="active">
              Recipes
            </DropdownItem>
            <DropdownItem tag={RRLink} to="/coach/diet/ingredients" activeClassName="active">
              Ingredients
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem tag={RRLink} to="/coach/explore" activeClassName="active">
              Explorer
            </DropdownItem>
            <DropdownItem tag={RRLink} to="/coach/explore/entries" activeClassName="active">
              Explore Entries
            </DropdownItem>
            <DropdownItem tag={RRLink} to="/coach/explore/faq" activeClassName="active">
              Explore FAQ
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem tag={RRLink} to="/coach/tts" activeClassName="active">
              Text To Speech
            </DropdownItem>
            {authentication.isGodAdmin && (
              <DropdownItem tag={RRLink} to="/coach/events" activeClassName="active">
                Events / Schedule
              </DropdownItem>
            )}
            <DropdownItem divider />
            {authentication.isGodAdmin && (
              <DropdownItem tag={RRLink} to="/coach/knowledge-base" activeClassName="active">
                Knowledge base
              </DropdownItem>
            )}
          </DropdownHeader>
          {/*<DropdownHeader dropdown={'messaging'} activeKey={dropdownOpen} label={'Messaging'} onToggle={toggleDropdown}>*/}
          {/*  <DropdownItem tag={RRLink} to="/messaging/email" activeClassName="active">*/}
          {/*    E-Mail*/}
          {/*  </DropdownItem>*/}
          {/*</DropdownHeader>*/}
          {authentication.hasAnyRole(['SALES']) && (
            <DropdownHeader dropdown={'analytics'} activeKey={dropdownOpen} label={'Analytics'} onToggle={toggleDropdown}>
              <DropdownItem tag={RRLink} to="/analytics" activeClassName="active">
                General Analytics
              </DropdownItem>
              <DropdownItem tag={RRLink} to="/analytics/company-dashboard" activeClassName="active">
                Company Dashboard
              </DropdownItem>
            </DropdownHeader>
          )}
          {authentication.isAdmin ? (
            <DropdownHeader
              dropdown={'customer-success'}
              activeKey={dropdownOpen}
              label={'Customer Success'}
              onToggle={toggleDropdown}
            >
              <DropdownItem tag={RRLink} to="/customer-success/campaign-calendar" activeClassName="active">
                Campaign Calendar
              </DropdownItem>
              <DropdownItem tag={RRLink} to="/customer-success/file-storage" activeClassName="active">
                File Storage
              </DropdownItem>
            </DropdownHeader>
          ) : null}
          {authentication.isGodAdmin ? (
            <DropdownHeader dropdown={'security'} activeKey={dropdownOpen} label={'Security'} onToggle={toggleDropdown}>
              <DropdownItem tag={RRLink} to="/security/user" activeClassName="active">
                Users
              </DropdownItem>
              <DropdownItem tag={RRLink} to="/security/userRole" activeClassName="active">
                User Roles
              </DropdownItem>
              <DropdownItem tag={RRLink} to="/security/oauth" activeClassName="active">
                OAuth Clients
              </DropdownItem>
              <DropdownItem tag={RRLink} to="/security/oauthScope" activeClassName="active">
                OAuth Scope
              </DropdownItem>
            </DropdownHeader>
          ) : null}
          <DropdownHeader dropdown={'tools'} activeKey={dropdownOpen} label={'Tools'} onToggle={toggleDropdown}>
            <DropdownItem tag={RRLink} to="/tools/dynamic-link-builder" activeClassName="active">
              Dynamic Link Builder
            </DropdownItem>
            <DropdownItem tag={RRLink} to="/tools/qr-code-builder" activeClassName="active">
              QR Code Builder
            </DropdownItem>
            <DropdownItem tag={RRLink} to="/tools/deepl" activeClassName="active">
              DeepL
            </DropdownItem>
          </DropdownHeader>
        </Nav>
        <Nav>
          <NavItem className="nav-item" style={{ display: 'flex', alignItems: 'center' }}>
            <small style={{ color: 'white' }}>{authentication.athlete?.user.email}</small>
            <NavLink onClick={handleLogout}>Logout</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
});
