/**
 * Created by neo on 04.10.23
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { GymChallenge } from '../../../Model/GymChallenge/GymChallenge';
import { Col, Container, Row, Table } from 'reactstrap';
import { Button } from 'antd';
import { SingleColRow } from '../../../Components/SingleColRow';
import { Route, Routes } from 'react-router-dom';
import { GymChallengeTableRow } from './GymChallengeTableRow';
import { PageResult } from '../../../Model/PageResult';
import { Pager } from '../../../Components/Pager';
import { GymChallengeModal } from './GymChallengeModal';

export type ChallengeListViewScreenProps = {};

export const ChallengeListViewScreen: React.FC<ChallengeListViewScreenProps> = observer((props) => {
  const history = useNavigate();

  const [challenges, setChallenges] = useState<PageResult<GymChallenge>>(new PageResult());
  const [page, setPage] = useState(0);

  useEffect(() => {
    PageResult.execute(
      GymChallenge.find({ page, sort: 'startDateTime,DESC', size: 20 }),
      GymChallenge.count(),
      page,
      20,
    ).then((result) => setChallenges(result));
  }, [page]);

  const handleCreateChallenge = React.useCallback(() => {
    history(`new`);
  }, [history]);

  return (
    <Container>
      <Row>
        <Col />
        <Col xs="auto">
          <Button type="primary" onClick={handleCreateChallenge}>
            Create new Challenge
          </Button>
        </Col>
      </Row>
      <SingleColRow>
        <Pager page={challenges} onPage={(newPage) => setPage(newPage)} />
      </SingleColRow>
      <SingleColRow>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Gym</th>
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
              <th>Status</th>
              <th>Points</th>
              <th>Participants</th>
            </tr>
          </thead>
          <tbody>
            {challenges.map((challenge) => (
              <GymChallengeTableRow key={challenge.id} challenge={challenge} />
            ))}
          </tbody>
        </Table>
      </SingleColRow>
      <SingleColRow>
        <Pager page={challenges} onPage={(newPage) => setPage(newPage)} />
      </SingleColRow>
      <Routes>
        <Route path=":challengeId" element={<GymChallengeModal />} />
      </Routes>
    </Container>
  );
});
