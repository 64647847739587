/**
 * Created by neo on 21.06.23
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { ExploreContent } from '../../../../Model/Explore/ExploreContent';
import { ExploreContentBuilder } from '../../../../Model/Explore/ExploreContentBuilder';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { GptResponseService } from '../../../../Services/GptResponseService';
import { runInAction } from 'mobx';

export type TagAllContentComponentProps = {};

export const TagAllContentComponent: React.FC<TagAllContentComponentProps> = observer((props) => {
  const [tagging, setTagging] = useState(false);

  const tagEntry = React.useRef((entry: ExploreEntry) => {
    if (entry.getDescription('en').trim()) {
      return new GptResponseService([
        {
          role: 'system',
          content: `Provide all responses as JSON string array. The response has the be a clean JSON string array that can be parsed. The response has to start with "[" and end with "]".
             If the entry provided is a workout determine if it requires equipment or not. If no equipment is required then you HAVE TO include 
             the tag "equipment:free" in the list of tags. 
             If equipment is required then you HAVE TO include the tag "equipment:custom" or "equipment:gym" in the list of tags.
             When a list of equipmentTypes is provided then it can have both "equipment:custom" and or "equipment:gym".
             A workout is something where the "type" = "workoutTemplate", "workout", "activityWorkout".
             Furthermore YOU HAVE TO in every case always include either all of the tags "morning", "afternoon", "evening", "night" in the list of tags 
             or just the ones you seem appropriate for this entry.`,
        },
        {
          role: 'user',
          content: `Do not include "\`\`\`json" in the beginning of the response. Return me an unformatted clean JSON string array that can be parsed. 
          The JSON of this entry looks the following: 
          ${JSON.stringify(entry, null, 2)}
          Give me a maximum 10 tags for the following content: "${entry.getDescription('en')}".
          If "equipmentTypes" are provided then include them within the tags and disregard the tags limit.
          `,
        },
      ])
        .generate()
        .then((result) => {
          console.log('result', result);
          return result;
        })
        .then((result) => {
          try {
            return JSON.parse(result);
          } catch (e) {
            return [];
          }
        })
        .then((res) => runInAction(() => (entry.tags = [...new Set<string>(res.map((e) => e.toLowerCase()))])));
      // .then((res) =>
      //   runInAction(
      //     () =>
      //       (entry.tags = [
      //         ...new Set<string>(
      //           res.map((e) => e.toLowerCase()).concat(['morning', 'afternoon', 'evening', 'night']),
      //         ),
      //       ]),
      //   ),
      // );
    } else {
      runInAction(
        () => (entry.tags = [...new Set<string>(entry.tags.concat(['morning', 'afternoon', 'evening', 'night']))]),
      );
    }
    return Promise.resolve();
  }).current;

  const tagContent = React.useRef(async () => {
    const SIZE = 10;
    let page = 0;
    let results: ExploreContent[] | undefined;
    while (!results || results.length >= SIZE) {
      results = await ExploreContentBuilder.find({ size: SIZE, contentType: 'entry', page });

      if (results.length > 0) {
        await Promise.all(results.map((e) => tagEntry(e as ExploreEntry).then(() => e.save())));
      }
      page += 1;
    }
  }).current;

  const handleTagAll = React.useCallback(async () => {
    setTagging(true);
    try {
      await tagContent();
    } finally {
      setTagging(false);
    }
  }, [tagContent]);

  return (
    <Button onClick={handleTagAll} disabled={tagging}>
      Tag All Content
    </Button>
  );
});
